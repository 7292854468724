@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  src: url("merriweather-v20-latin-regular.a78e2fcf.eot");
  src: local(Merriweather Regular), local(Merriweather-Regular), url("merriweather-v20-latin-regular.a78e2fcf.eot#iefix") format("embedded-opentype"), url("merriweather-v20-latin-regular.734e3f2a.woff2") format("woff2"), url("merriweather-v20-latin-regular.6541fd6c.woff") format("woff"), url("merriweather-v20-latin-regular.927fe0c6.ttf") format("truetype"), url("merriweather-v20-latin-regular.8dd4cb8e.svg#Merriweather") format("svg");
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 700;
  src: url("merriweather-v20-latin-700.e25aa54f.eot");
  src: local(Merriweather Bold), local(Merriweather-Bold), url("merriweather-v20-latin-700.e25aa54f.eot#iefix") format("embedded-opentype"), url("merriweather-v20-latin-700.9b26c1e9.woff2") format("woff2"), url("merriweather-v20-latin-700.d19ffac8.woff") format("woff"), url("merriweather-v20-latin-700.00d24143.ttf") format("truetype"), url("merriweather-v20-latin-700.cc1b4d63.svg#Merriweather") format("svg");
}

/*# sourceMappingURL=index.5264616e.css.map */
